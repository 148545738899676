@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

body {
       background-color: #f1f1f1!important;
       font-family: "Quicksand", sans-serif !important;
       font-weight: 300;
       line-height: 1.5;
}

.light-bg {
       color: #f1f1f1!important;
}

.theme-bg {
       background-color: #892cdc!important;
}

.dark-bg {
       background-color: #000000!important;
}

.theme-text {
       color: #892cdc!important;
}

.btn-outline-purple {
       --bs-btn-color: #892cdc!important;
       --bs-btn-border-color: #892cdc!important;
       --bs-btn-hover-color: #fff;
       --bs-btn-hover-bg: #892cdc!important;
       --bs-btn-hover-border-color: #892cdc!important;
       --bs-btn-focus-shadow-rgb: 33,37,41;
       --bs-btn-active-color: #fff!important;
       --bs-btn-active-bg: #892cdc!important;
       --bs-btn-active-border-color: #892cdc!important;
       --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
       --bs-btn-disabled-color: #892cdc!important;
       --bs-btn-disabled-bg: transparent;
       --bs-gradient: none;
}

.none-a {
       text-decoration: none;
}

.none-a:hover {
       font-weight: 600!important;
}

.nav-item:hover {
       cursor: pointer!important;
}

.active-tab {
       background-color: #892cdc;
       color: white;
}

.list-group-item:hover {
       cursor: pointer!important;
}

.MuiPagination-ul {
       justify-content: center!important;
}






/* loading */

.custom-loading ul {
       /* position: absolute;
       top: 30%;
       left: 50%; */
       transform: translate(-50%, 50%);
       margin: 0;
       padding: 0;
       display: flex;
}
   
.custom-loading ul li {
       list-style: none;
       width: 20px;
       height: 20px;
       background: #000;
       margin: 0 5px;
       border-radius: 50%;
       animation: animate 1.4s linear infinite
}
   
@keyframes animate {
       0% {
           transform: translateY(0)
       }
   
       60% {
           transform: translateY(0)
       }
   
       80% {
           transform: translateY(-20px)
       }
   
       100% {
           transform: translateY(0px)
       }
}
   
.custom-loading ul li:nth-child(1) {
       animation-delay: -1.2s
}
   
.custom-loading ul li:nth-child(2) {
       animation-delay: -1.0s
}
   
.custom-loading ul li:nth-child(3) {
       animation-delay: -.8s
}
   
.custom-loading ul li:nth-child(4) {
       animation-delay: -0.6s
}